<template>
  <div
    class="pl-3 pt-1"
  >
    <v-card
      class="test-plans-section-header white pt-4 px-6 pb-4 mt-2"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <div class="d-flex flex-column">
        <div class="d-flex flex-row align-center justify-space-between">
          <button
            class="btn-back d-flex"
            @click="handleBackClick"
          >
            <v-icon color="blue">
              mdi-chevron-left
            </v-icon>
            <span class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
              {{ $t('plans.create.backToPlans') }}
            </span>
          </button>
          <div class="d-flex flex-row justify-end align-center">
            <h4 class="font-weight-light mr-2">
              Status:
            </h4>
            <div>
              <v-menu
                v-model="menuOpen"
                bottom
                offset-y
                :nudge-top="4"
                right
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    dark
                    large
                    color="gray-100"
                    class="text-capitalize font-weight-light black--text px-3 py-2"
                    depressed
                    height="40"
                    v-on="on"
                  >
                    {{ testPlan.status }}
                    <v-icon size="20">
                      {{ menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list
                  dense
                  class="text-left"
                >
                  <v-list-item @click="testPlan.status = 'new'">
                    <v-list-item-title>{{ $t('plans.edit.new') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="testPlan.status = 'in progress'">
                    <v-list-item-title>{{ $t('plans.edit.inProgress') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="testPlan.status = 'passed'">
                    <v-list-item-title>{{ $t('plans.edit.passed') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="testPlan.status = 'rerun'">
                    <v-list-item-title>{{ $t('plans.edit.rerun') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="testPlan.status = 'in failed'">
                    <v-list-item-title>{{ $t('plans.edit.failed') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column justify-start">
          <v-hover
            v-if="!isEditPlan"
            v-slot="{ hover }"
          >
            <div class="d-flex flex-row">
              <h2 class="edit-plan">
                {{ testPlan.name }}
              </h2>
              <button
                class="btn-hide"
                :class="{'btn-show': hover}"
                @click="handleEditPlanDetail"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </button>
            </div>
          </v-hover>
          <v-text-field
            v-else
            v-model="testPlan.name"
            class="plan-name edit-plan font-weight-bold"
            solo
            flat
            hide-details="true"
            background-color="#F9FAFB"
          />
          <p
            v-if="!isEditPlan"
            class="edit-plan"
            style="color: #667085; font-size: 14px; line-height: 14px"
          >
            {{ testPlan.description }}
          </p>
          <v-textarea
            v-else
            v-model="testPlan.description"
            class="edit-plan pt-0 mt-1 plan-description"
            dense
            solo
            flat
            auto-grow
            hide-details="true"
            background-color="#F9FAFB"
            height="44"
          />
        </div>
        <div class="d-flex justify-space-between">
          <div class="d-flex flex-column w-33">         
            <div class="text-left">
              <v-label class="fs-14px text-theme-label font-weight-medium">
                {{ $t('plans.edit.milestone') }}
              </v-label>
            </div>
            <v-select
              v-model="selectedMilestones"
              dense
              background-color="#F9F9FB"
              :items="items"
              item-key="uid"
              item-value="uid"
              multiple
              item-text="name"
              class="rounded-lg field-theme custom-prepend mh-38px"
              append-icon="mdi-chevron-down"
              :placeholder="$t('testruns.milestone_dialog.content')"
              :hide-details="true"
              :menu-props="{ offsetY: true }"
            >
              <template v-slot:selection="{ item }">
                <div
                  class="d-flex align-center custom-chip-theme mr-1 mb-1"
                >
                  <div class="text-theme-label label text-truncate mr-1">
                    {{ item.name }}
                  </div>
                  <v-icon
                    size="16px"
                    @click="onRemoveSelectedMilestone(item.uid)"
                  >
                    mdi-close
                  </v-icon>
                </div>
              </template>

              <template v-slot:item="{ item, on }">
                <v-list-item
                  :ripple="false"
                  v-on="on"
                >
                  <v-list-item-action>
                    <v-checkbox
                      hide-details
                      :input-value="milestoneSelection(item.uid)"
                      class="field-theme mt-0 pt-0"
                      :ripple="false"
                      off-icon="icon-checkbox-off"
                      on-icon="icon-checkbox-on"
                    >
                      <template v-slot:label>
                        <span class="fs-14px text-theme-label">{{ `${item.name}` }}</span>
                      </template>
                    </v-checkbox>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-select>
          </div>
          <div class="d-flex flex-column w-33 mx-4">
            <div class="text-left">
              <v-label class="fs-14px text-theme-label font-weight-medium">
                {{ $t('plans.edit.priority') }}
              </v-label>
            </div>
            <v-select
              v-model="testPlan.priority"
              dense
              background-color="#F9F9FB"
              :items="priorities"
              append-icon="mdi-chevron-down"
              class="rounded-lg field-theme custom-prepend mh-38px"
              :hide-details="true"
              :menu-props="{ offsetY: true }"
              :placeholder="$t('testruns.selectPriority')"
            />
          </div>
          <div class="d-flex flex-column w-33">
            <div class="text-left">
              <v-label class="fs-14px text-theme-label font-weight-medium">
                {{ $t('plans.edit.tags') }}
              </v-label>
            </div>
            <v-select
              v-model="selectedTags"
              background-color="#F9F9FB"
              dense
              :items="tags"
              item-key="uid"
              item-value="name"
              item-text="name"
              :placeholder="$t('testruns.selectTags')"
              class="rounded-lg field-theme custom-prepend mh-38px"
              append-icon="mdi-chevron-down"
              hide-details="true"
              :menu-props="{ offsetY: true }"
              multiple
            >
              <template v-slot:selection="{ item }">
                <div
                  class="d-flex align-center custom-chip-theme mr-1 mb-1"
                >
                  <div class="text-theme-label label text-truncate mr-1">
                    {{ item.name }}
                  </div>
                  <v-icon
                    size="16px"
                    @click="onRemoveSelectedTags(item.name)"
                  >
                    mdi-close
                  </v-icon>
                </div>
              </template>

              <template v-slot:item="{ item, on }">
                <v-list-item
                  :ripple="false"
                  v-on="on"
                >
                  <v-list-item-action>
                    <v-checkbox
                      hide-details
                      :input-value="tagsSelection(item.name)"
                      class="field-theme mt-0 pt-0"
                      :ripple="false"
                      off-icon="icon-checkbox-off"
                      on-icon="icon-checkbox-on"
                    >
                      <template v-slot:label>
                        <span class="fs-14px text-theme-label">{{ `${item.name}` }}</span>
                      </template>
                    </v-checkbox>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </div>
      </div>
    </v-card>
    <detail-section-header
      :tab="tableFilter"
      :data="runItems"
      :selected-runs-count="testPlan.runs?.length || 0"
      @tab="handleTab"
    />
    <div class="app-height-global white rounded-lg">
      <placeholder v-if="runItems.length === 0" />
      <v-card
        v-else
        class="py-6 px-6"
        rounded="lg"
        elevation="0"
        width="100%"
      >
        <div class="d-flex">
          <search-field
            v-model="searchFilter"
            class="search-input mr-2"
            :placeholder="$t('searchByName')"
          />
          <!-- <plans-list-filter @filters="applyFilters" /> -->
          <div class="ml-auto">
            <SettingsMenu 
              table-type="planDetailsView" 
            />
          </div>
        </div>
        <test-run-list
          :data="runItems"
          :tab="tableFilter"
          :selected-runs="testPlan.runs"
          :headers="filteredHeaders"
          @selectTestRun="selectTestRun"
        />
      </v-card>

      <div class="d-flex flex-row justify-end py-4 px-6 action-btn-wrapper rounded-lg">
        <v-btn
          class="px-6 text-capitalize mr-3 action-btn rounded-lg"
          depressed
          @click="handleCancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          class="px-6 text-capitalize primary action-btn rounded-lg"
          depressed
          @click="handleUpdate"
        >
          {{ $t('save') }}
        </v-btn>
      </div>
      <confirm-dialog
        v-model="showConfirmBackDialog"
        :title="$t('plans.edit.discussConfirmation.title')"
        :description="$t('plans.edit.discussConfirmation.description')"
        :actions="[
          { label: $t('cancel'), color: 'secondary', action: 'close' },
          { label: $t('confirm'), color: 'primary', action: 'confirm' },
        ]"
        @close="showConfirmBackDialog = false"
        @confirm="handleConfirmClick"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters, mapState, mapActions as projectMapActions } from 'vuex';

import DetailSectionHeader from '@/views/Tests/Plans/Components/DetailSectionHeader';
import Placeholder from '@/views/Tests/Plans/Components/Placeholder';
import ConfirmDialog from '@/views/Tests/Plans/Components/ConfirmDialog';
import SearchField from '@/components/Form/SearchField';
import TestRunList from '@/views/Tests/Plans/Components/TestRunList';
import makeTagsService from '@/services/api/tag';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import { testPlansProgressColor } from '@/constants/data.js';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import makePlanService from '@/services/api/plan';
const { mapActions } = createNamespacedHelpers('plan');
const { mapActions: mapMilestoneActions } = createNamespacedHelpers('milestone');
const { mapActions: MapRunActions } = createNamespacedHelpers('run');
let planService;
let tagService;
export default {
  components: {
    Placeholder,
    SearchField,
    DetailSectionHeader,
    ConfirmDialog,
    TestRunList,
    SettingsMenu
  },
  data() {
    return {
      menuOpen: false,
      isEditPlan: false,
      testPlanData: {},
      priorities: ["High", "Medium", "Low"],
      isColumnFilter: false,
      headers: [],
      searchFilter: "",
      tableFilter: 'all',
      planDescription: "",
      planName: "Functionality Test Plan",
      showConfirmBackDialog: false,
      value: {
        id: 1,
        name: 'Smoke Test Run',
        priority: 'High',
        status: 'New',
        configurations: [{ title: 'Browsers' }],
        creationdate: '06/17/2023',
        tag: ['#projectateam'],
        progress: [20, 40, 60, 80],
      },
      tags: [],
      selectedTags:[],
      selectedMilestones: []
    };
  },
  computed: {
    ...mapState('plan', ['selectedRuns']),
    ...mapState('milestone', ['items']),
    ...mapState('run', {
      runItems: state => state.items
    }),
    ...mapState('plan', ['testPlan']),
    ...mapGetters({
      currentOrg: 'user/currentAccount',
      dynamicHeaders: 'headers/dynamicHeaders'
    }),
    filteredHeaders() {
      const filtered = this.headers.filter((header) => header.checked);
      if (filtered.length < this.headers.length)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = true
      else
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = false
      return filtered;
    },
  },
  watch: {
    testPlan: {
      handler(newVal) {
        // Update selectedTags when testPlan changes
        if (newVal && newVal.customFields && newVal.customFields.tags) {
          this.selectedTags = newVal.customFields.tags;
        }
        if (newVal && newVal.milestoneUids) {
          this.selectedMilestones = newVal.milestoneUids;
        }
      },
      immediate: true,
    },
  },
  created() {
    if(!this.dynamicHeaders.planDetailsView) {
      this.initializeHeaders({ type: 'planDetailsView' });
    }
    this.headers = this.dynamicHeaders.planDetailsView;
    planService = makePlanService(this.$api);
    tagService = makeTagsService(this.$api);
  },
   async mounted() {
    const { handle, planId, key } = this.$route.params;
    this.selectedMilestones = this.testPlan.testMilestoneUid

    await this.findTestPlan({ handle, planId, key })

    // get tag from backend 
    await this.getAllTags();
    this.get({ handle, projectKey: key });
    this.getAllTestRuns({ handle: handle, projectKey: this.$route.params.key, perPage: 99999, currentPage: 0 });
  },
  methods: {
    ...mapActions(['findTestPlan']),
    ...MapRunActions(['getAllTestRuns']),
    ...mapMilestoneActions(['get']),
    ...projectMapActions("headers", ['initializeHeaders']),
    handleCancel() {
      this.showConfirmBackDialog = true;
    },
    milestoneSelection(uid) {
      return this.selectedMilestones ? this.selectedMilestones.some((id) => id === uid) : false;
    },
    tagsSelection(tagName) {

      if (typeof this.selectedTags === 'string') {
        this.selectedTags = [this.selectedTags];
      } else if (!Array.isArray(this.selectedTags)) {
        this.selectedTags = [];
      }

      return this.selectedTags ? this.selectedTags.some((name) => name === tagName) : false;
    },
    onRemoveSelectedMilestone(uid) {
      const index = this.selectedMilestones.indexOf(uid);
      if (index !== -1) {
        this.selectedMilestones.splice(index, 1);
      }
    },
    onRemoveSelectedTags(uid) {
      const index = this.selectedTags.indexOf(uid);
      if (index !== -1) {
        this.selectedTags.splice(index, 1);
      }
    },
      async getAllTags() {
      try {
        const response = await tagService.getTags(
          this.$route.params.handle,
          this.$route.params.key
        );
        if (response.status === 200) {
          
          this.tags = response.data;

        }
      } catch (err) {
        console.log(err)
      } finally {
        this.selectedTags = this.testPlan?.customFields?.tags;
      }
    },
    async handleUpdate() {
      const runsList = this.testPlan.runs.map(
          ({
              uid,
              externalId,
              source,name,
              link,priority,
              status,
              testPlanUid,
              customFields: {tags}
          }) => ({
            uid,
            externalId,
            source,
            name,
            link,
            priority,
            status,
            testPlanUid,
            customFields: {tags}}
          ))
      const payload = {
        name: this.testPlan.name,
        description: this.testPlan.description,
        externalId: "1",
        source: "testfiesta",
        status: this.testPlan.status.toLowerCase(),
        milestoneUids: this.selectedMilestones,
        priority: this.testPlan.priority,
        runs: runsList || [],
        customFields: {
          status: this.testPlan.status.toLowerCase(),
          tags: this.selectedTags,
          configurations: 'Browsers',
          archived: false
        }
      }
      try{
        const response = await planService.updateTestPlan(
          this.$route.params.handle,
          this.$route.params.key,
          this.testPlan.uid,
          payload,
        );
        if(response.status === 200) {
          showSuccessToast(this.$swal, this.$t('success.testPlanUpdated'));
          this.$router.push({
            name: 'TestPlans',
            params: {
              handle: this.$route.params.handle,
              key: this.$route.params.key,
            }
          });
        }
      } catch(err){
        showErrorToast(this.$swal, this.$t('error.failedToUpdateTestPlan'));
      }
    },
    selectTestRun(selectedRuns) {
      this.testPlan.runs = selectedRuns
    },
    handleTab(value) {
      this.tableFilter = value;
    },
    handleEditPlanDetail() {
      this.isEditPlan = true;
    },
    handleBackClick() {
      this.$router.replace({ name: 'TestPlans' });
    },
    handleConfirmClick() {
      this.showConfirmBackDialog = false;
      this.$router.replace({ name: 'TestPlans' });
    },
    progress(item) {
      const result = item.progress.map((x) => {
        const color =
          x < 25
            ? testPlansProgressColor[0]
            : x < 50
            ? testPlansProgressColor[1]
            : x < 75
            ? testPlansProgressColor[4]
            : testPlansProgressColor[3];
        return {
          value: x,
          color,
        };
      });
      return result;
    },
  },
};
</script>

<style lang="scss">

.progress-container {
  position: relative;
  width: 158px;
  height: 8px;
  background: #eaecf0;
  overflow: hidden;
}

.btn-hide{
  display: none;
}

.btn-show{
  display: block !important;
}

.v-input.plan-name{
  .v-input__control{
    .v-input__slot{
      padding-left: 4px !important;
      input{
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}

.edit-plan{
  max-width: 476px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.plan-description{
  .v-input__control{
    .v-input__slot{
      padding-left: 4px !important;
      textarea{
        line-height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #667085;
        height: 44px !important;
      }
    }
  }
}

.action-btn{
  width: 140px;
}
.action-btn-wrapper {
  position: sticky;
    bottom: 0;
    background-color: white;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    z-index: 8;
}

</style>
